import React, { FC } from "react";
import Layout from "../../components/Layout/Layout";
import { SEO } from "../../components/seo";
import ThankYouMain from "../../components/ThankYouUiComponents/ThankYouMain";

type Props = {
  location: any;
};

const ThankYou: FC<Props> = ({ location }) => {
  return (
    <Layout location="/thank-you">
      <SEO title="Thank you" />
      <ThankYouMain location={location.pathname} isServicesPage={true} />
    </Layout>
  );
};

export default ThankYou;
